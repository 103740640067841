<template>
  <div class="mb-test-questions">

    <div class="mb-test-question">
      <div class="mb-test-question-question">
        {{ question }}
      </div>
      <div class="mb-test-question-options">
        <b-button
            @click="onYesClick"
            class="mb-test-question-option mb-test-question-options-yes"
            v-bind:style="{ 'background-color': computedYesColor, 'border-color': computedYesColor }"
        >
          {{ $t('test_page.safeometer_test_yes') }}
        </b-button>
        <b-button
            @click="onNoClick"
            class="mb-test-question-option mb-test-question-options-no"
            v-bind:style="{ 'background-color': computedNoColor, 'border-color': computedNoColor }"
        >
          {{ $t('test_page.safeometer_test_no') }}
        </b-button>
        <a @click="onSkip"  class="mb-test-question-options-skip" href="#">
          {{ $t('test_page.safeometer_test_skip') }}
        </a>
      </div>
    </div>

    <b-button to="/contact" class="mb-test-attention-line">
      <img src="@/assets/images/icons/telephone_white.svg" alt="Telephone">
      {{$t('global.safeometer_helplines')}}
    </b-button>

  </div>
</template>

<script>

export default {
  name: "Questions",
  mounted () {
    this.loadAndUpdateQuestions();
    this.question = this.questions[this.currentQuestion].question;
  },
  data(){
    return {
      question: "",
      totalPoints: -1,
      currentPoints: 0,
      currentQuestion: 0,
      questions: [],
      yesColor: "#868686",
      noColor: "#868686",
      btnOptionBaseColor: "#868686",
      btnOptionRedColor: "#CB0000",
      btnOptionBlueColor: "#0468b1",
      defaultLang: ''
    }
  },
  methods: {
    loadAndUpdateQuestions(){
      if(this.defaultLang !== this.$i18n.locale){
        this.defaultLang = this.$i18n.locale;
        this.questionData = require('../data/' + this.$i18n.locale + '.json')['questions'];

        if(this.questions.length === 0){
          this.questionData.forEach( qData => {
            this.questions.push(
                {
                  question: this.$t('test_page.questions.' + qData['label']),
                  selection: 0,
                  points: qData['points'],
                  critical: qData['critical'],
                  type: qData['type']
                }
            );
          });
        }else{
          let pos = 0;
          this.questionData.forEach( qData => {
            this.questions[pos]['question'] = this.$t('test_page.questions.' + qData['label']);
            this.questions[pos]['points'] = qData['points'];
            this.questions[pos]['critical'] = qData['critical'];
            this.questions[pos]['type'] = qData['type'];
            pos++;
          });
        }

      }

    },
    updateProgress() {
      this.$emit('updated', ((100/this.totalPoints) * this.currentPoints), this.currentPoints);
    },
    updateMarkerBackground() {
      this.$emit('updateMarkerBackground', this.questions[this.currentQuestion]['type'] );
    },
    onYesClick (){
      if(this.currentQuestion < this.questions.length) {
        this.questions[this.currentQuestion].selection = 1;
        this.updatePoints(this.currentQuestion, 1);
      }
    },
    onNoClick (){
      if(this.currentQuestion < this.questions.length) {
        this.questions[this.currentQuestion].selection = -1;
        this.updatePoints(this.currentQuestion, -1);
      }
    },
    onSkip (){
      if(this.currentQuestion < this.questions.length) {
        this.questions[this.currentQuestion].selection = 0;
        this.updatePoints(this.currentQuestion, 0);
      }
    },
    updatePoints (questionNum, selection) {

      if(this.totalPoints === -1){
        this.totalPoints = 0;
        this.questions.forEach(question => {
          this.totalPoints += question.points;
        })
      }

      let index = 0;
      this.currentPoints = 0;
      this.questions.forEach(question => {
        if(index !== questionNum){
          if(question.critical && question.selection === 1){
            this.currentPoints += question.points;
          }else{
            this.currentPoints += question.points * (question.selection > 0 ? 1 : 0);
          }
        }else{
          if(question.critical && selection === 1){
            this.currentPoints += question.points;
          }else{
            this.currentPoints += question.points * (selection > 0 ? 1 : 0);
          }
          if(selection === 1){
            this.updateMarkerBackground();
          }
        }
        index++;
      });

      this.updateProgress();
      this.next(selection);

    },
    next(selection){
      this.currentQuestion++;

      this.loadAndUpdateQuestions();

      if(selection){
        if(selection === 1){
          this.yesColor = this.btnOptionRedColor;
        }else if(selection === -1) {
          this.noColor = this.btnOptionBlueColor;
        }
      }

      if(selection === 1 || selection === -1){
        setTimeout(() => {
          this.yesColor = this.btnOptionBaseColor;
          this.noColor = this.btnOptionBaseColor;
          this.loadQuestion();
        }, 300);
      }else{
        this.loadQuestion();
      }

    },
    loadQuestion(){
      if(this.currentQuestion < this.questions.length){
        this.question = this.questions[this.currentQuestion].question;
      }else{
        this.$emit('endTestListener', (100 / this.totalPoints) * this.currentPoints, this.currentPoints);
      }
    }
  },
  computed: {
    computedYesColor(){
      return this.yesColor;
    },
    computedNoColor(){
      return this.noColor;
    },
  }
}
</script>

<style scoped>
  .mb-test-questions{
    padding-bottom: 30px;
    padding-top: 100px;
  }
  .mb-test-attention-line,
  .mb-test-attention-line:hover {
    background-color: #0468B1;
    border-color: #0468B1;
  }
  .mb-test-attention-line {
    vertical-align: middle;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 20px;
    margin-bottom: 15px;
    font-size: 23px;
    line-height: 29px;
    margin-top: 2em;
  }
  .mb-test-attention-line > img {
    margin-right: 0.5em;
    height: 30px;
  }
  .mb-arabic .mb-test-attention-line > img{
    margin-right: 0em;
    margin-left: 0.5em;
  }
  .mb-test-question-question {
    font-family: Nunito Semibold;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 27px;
    font-size: 22px;
    color: #4B4C4D;
  }
  .mb-test-question-options {
    font-family: Nunito Semibold;
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px;
  }
  .mb-test-question-options button{
    font-size: 22px;
    line-height: 27px;
    padding: 15px 41px;
  }
  .mb-test-question-option:focus {
    box-shadow: none !important;
  }
  .mb-test-question-options-yes {
    margin-right: 0.5em;
  }
  .mb-test-question-options-no {
    margin-left: 0.5em;
  }
  .mb-arabic .mb-test-question-options-no{
    margin-right: 0.5em;
    margin-left: 0;
  }
  .mb-arabic .mb-test-question-options-yes{
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .mb-test-question-option{
    padding: 10px 25px 10px 25px;
    background-color: #868686;
    border-radius: 10px;
  }
  .mb-test-question-options-skip {
    text-decoration: underline;
    background-color: white;
    text-align: right;
    margin-right: 7em;
    margin-top: 2em;
    color: #0468B1;
    display: block;
  }
  .mb-arabic .mb-test-question-options-skip {
    margin-left: 7em;
    text-align: left;
  }

  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-test-question-question{
      line-height: 22px;
      font-size: 18px;
    }
    .mb-test-question-options button {
      padding: 4px 19px;
      line-height: 22px;
      font-size: 18px;
    }
    .mb-test-attention-line {
      padding: 3px 1.5em 3px 1.5em;
      line-height: 23px;
      font-size: 18px;
    }
  }
</style>