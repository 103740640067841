<template>
  <div>
    <div class="mb-contact-header">
      <div class="mb-contact-header-title">
        {{ $t('global.safeometer_helplines') }}
      </div>
    </div>
    <div class="mb-contact-container">
      <div class="mb-contact-container-msg">
        {{ $t('helplines_page.safeometer_helplines_description') }}
      </div>
      <label class="mb-contact-selector-label">
        {{ $t('helplines_page.safeometer_helplines_select_country') }}
      </label>
      <select @change="selectCountry($event)" ref="mb-contact-country-selector" class="mb-contact-selector-selector custom-select">

        <option
            value="all"
            data-emergency-lawe=""
            data-emergency-ws=""
            data-vaw-hotlines=""
            data-vaw-ws=""
        >
          {{$t('helplines_page.safeometer_helplines_all')}}
        </option>

        <option
            v-for="country in countries"
            :value="country"
            :key="country"
            :data-emergency-lawe="$t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement')"
            :data-emergency-ws="$t('countries.' + country + '.safeometer_helplines_emergency_ws')"
            :data-vaw-hotlines="$t('countries.' + country + '.safeometer_helplines_vaw_hotlines')"
            :data-vaw-ws="$t('countries.' + country + '.safeometer_helplines_vaw_ws')"
        >
          {{$t('countries.' + country + '.safeometer_' + country)}}
        </option>
      </select>
      <div v-bind:style="{ display: (computedShowAll === 'none' ? 'block' : 'none') }">
        <ContactPhone
            class="mb-contact-phone"
            :label="$t('helplines_page.safeometer_helplines_emergency_lawEnforcement')"
            :phone="emeLawe"
            :bglabel="bgLabel[0]"
            :bgPhone="bgPhone[0]"
            v-bind:style="{ display: emeLawe != '' ? 'block' : 'none'  }">
        </ContactPhone>
        <ContactPhone
            class="mb-contact-phone"
            :label="$t('helplines_page.safeometer_helplines_emergency_ws')"
            :phone="emeWs"
            :bglabel="bgLabel[1]"
            :bgPhone="bgPhone[1]"
            v-bind:style="{ display: emeWs != '' ? 'block' : 'none'  }">
        </ContactPhone>
        <ContactPhone
            class="mb-contact-phone"
            :label="$t('helplines_page.safeometer_helplines_vaw_hotlines')"
            :phone="vawHot"
            :bglabel="bgLabel[2]"
            :bgPhone="bgPhone[2]"
            v-bind:style="{ display: vawHot != '' ? 'block' : 'none'  }">
        </ContactPhone>
        <ContactPhone
            class="mb-contact-phone"
            :label="$t('helplines_page.safeometer_helplines_vaw_ws')"
            :phone="vawWs"
            :bglabel="bgLabel[3]"
            :bgPhone="bgPhone[3]"
            v-bind:style="{ display: vawWs != '' ? 'block' : 'none'  }">
        </ContactPhone>
      </div>
      <div class="mb-contact-all" v-bind:style="{ display: computedShowAll }">
        <div class="mb-contact-all-country" v-for="country in countries" :key="country">
          <div class="mb-contact-all-country-name">
            {{$t('countries.' + country + '.safeometer_' + country)}}
          </div>
          <ContactPhone
              class="mb-contact-phone"
              :label="$t('helplines_page.safeometer_helplines_emergency_lawEnforcement')"
              :phone="$t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement')"
              :bglabel="getLabelBackgroundColor(calculateBackground(0,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              :bgPhone="getPhoneBackgroundColor(calculateBackground(0,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              v-bind:style="{
                display: $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement') != '' ? 'block' : 'none'
              }">
          </ContactPhone>
          <ContactPhone
              class="mb-contact-phone"
              :label="$t('helplines_page.safeometer_helplines_emergency_ws')"
              :phone="$t('countries.' + country + '.safeometer_helplines_emergency_ws')"
              :bglabel="getLabelBackgroundColor(calculateBackground(1,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              :bgPhone="getPhoneBackgroundColor(calculateBackground(1,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              v-bind:style="{ display: $t('countries.' + country + '.safeometer_helplines_emergency_ws') != '' ? 'block' : 'none' }">
          </ContactPhone>
          <ContactPhone
              class="mb-contact-phone"
              :label="$t('helplines_page.safeometer_helplines_vaw_hotlines')"
              :phone="$t('countries.' + country + '.safeometer_helplines_vaw_hotlines')"
              :bglabel="getLabelBackgroundColor(calculateBackground(2,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              :bgPhone="getPhoneBackgroundColor(calculateBackground(2,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              v-bind:style="{ display: $t('countries.' + country + '.safeometer_helplines_vaw_hotlines') != '' ? 'block' : 'none' }">
          </ContactPhone>
          <ContactPhone
              class="mb-contact-phone"
              :label="$t('helplines_page.safeometer_helplines_vaw_ws')"
              :phone="$t('countries.' + country + '.safeometer_helplines_vaw_ws')"
              :bglabel="getLabelBackgroundColor(calculateBackground(3,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              :bgPhone="getPhoneBackgroundColor(calculateBackground(3,
                [
                    $t('countries.' + country + '.safeometer_helplines_emergency_lawEnforcement'),
                    $t('countries.' + country + '.safeometer_helplines_emergency_ws'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_hotlines'),
                    $t('countries.' + country + '.safeometer_helplines_vaw_ws')
                  ]
                ))"
              v-bind:style="{ display: $t('countries.' + country + '.safeometer_helplines_vaw_ws') != '' ? 'block' : 'none' }">
          </ContactPhone>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactPhone from "@/components/ContactPhone";
export default {
  name: "Contact",
  components: {
    ContactPhone
  },
  data () {
    return {
      countries:[
        "ARG", "BOL", "BRA", "CHL", "COL",
        "CRI", "CUB", "ECU", "SLV", "GTM",
        "HTI", "HND", "MEX", "NIC", "PAN",
        "PRY", "PER", "DOM", "URY", "VEN"
      ],
      emeLawe: "",
      emeWs: "",
      vawHot: "",
      vawWs: "",
      showAll: "none",
      bgLabel: ["transparent","transparent","transparent","transparent"],
      bgPhone: ["transparent","transparent","transparent","transparent"]
    }
  },
  methods: {
    selectCountry(event){
      this.loadCountry(event.target);
    },
    loadCountry(selector){
      this.showAll = selector.value === 'all' ? 'block' : 'none';
      this.emeLawe = selector[selector.selectedIndex].getAttribute('data-emergency-lawe');
      this.emeWs = selector[selector.selectedIndex].getAttribute('data-emergency-ws');
      this.vawHot = selector[selector.selectedIndex].getAttribute('data-vaw-hotlines');
      this.vawWs = selector[selector.selectedIndex].getAttribute('data-vaw-ws');

      let pLabels = [this.emeLawe, this.emeWs, this.vawHot, this.vawWs];
      let backgroundList = this.calculateBackgroundList(pLabels);

      this.bgLabel[0] = this.getLabelBackgroundColor(backgroundList[0]);
      this.bgLabel[1] = this.getLabelBackgroundColor(backgroundList[1]);
      this.bgLabel[2] = this.getLabelBackgroundColor(backgroundList[2]);
      this.bgLabel[3] = this.getLabelBackgroundColor(backgroundList[3]);

      this.bgPhone[0] = this.getPhoneBackgroundColor(backgroundList[0]);
      this.bgPhone[1] = this.getPhoneBackgroundColor(backgroundList[1]);
      this.bgPhone[2] = this.getPhoneBackgroundColor(backgroundList[2]);
      this.bgPhone[3] = this.getPhoneBackgroundColor(backgroundList[3]);

    },
    calculateBackground(position, labels)
    {
      return this.calculateBackgroundList(labels)[position];
    },
    calculateBackgroundList(labels)
    {
      let color1 = 2;
      let color2 = 3;
      let colors = [];
      labels.forEach(label => {
        if(label !== "" && colors.length === 0){
          colors.push(color1);
        }else if(label !== "" && colors.length > 0){
          colors.push(
              colors[colors.length - 1] === color2 ? color1 : color2
          );
        }else{
          colors.push(0);
        }
      });

      return colors;
    },
    getLabelBackgroundColor(type){
      switch (type) {
        case 1:
          return "transparent";
        case 2:
          return "#F2F9FF";
        case 3:
          return "#FFF";
      }
    },
    getPhoneBackgroundColor(type){
      switch (type) {
        case 1:
          return "transparent";
        case 2:
          return "#0468B1";
        case 3:
          return "#4899D5";
      }
    }
  },
  mounted() {
    this.loadCountry(
        this.$refs['mb-contact-country-selector']
    );
  },
  computed: {
    computedShowAll(){
      return this.showAll;
    }
  }
}
</script>

<style scoped>
  .mb-contact-phone {
    margin-bottom: 12px;
  }
  .mb-contact-phone:last-of-type{
    margin-bottom: 5em;
  }
  .mb-contact-all-country .mb-contact-phone:last-of-type{
    margin-bottom: 0;
  }
  .mb-contact-container {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
  }
  .mb-contact-header {
    background-color: #F2F9FF;
    padding: 12px 0 12px 0;
    width: 100%;
  }
  .mb-contact-header-title {
    font-family: Lora;
    font-weight: bold;
    line-height: 45px;
    font-size: 35px;
    color: #0468B1;
  }
  .mb-contact-container-msg {
    font-family: Nunito Semibold;
    margin: 10px 0 6px 0;
    line-height: 22px;
    font-size: 18px;
    color: #4B4C4D;
  }
  .mb-contact-selector-label {
    display: block;
    width: 100%;
    line-height: 22px;
    font-size: 18px;
  }
  .mb-contact-selector-selector {
    margin: 0 0 12px 0;
    width: 100%;
  }
  .custom-select {
    border-color: #0468B1;
    position: relative;
    border-radius: 0;
    color: #0468B1;
  }
  .mb-contact-all-country-name{
    font-family: "Nunito Semibold";
    margin-bottom: 0.3em;
    margin-top: 0.3em;
    font-size: 18px;
    color: #3E3E3E;
  }
  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-contact-header-title {
      line-height: 36px;
      font-size: 28px;
    }
    .mb-contact-container-msg {
      line-height: 20px;
      font-size: 16px;
    }
    .mb-contact-selector-label {
      line-height: 20px;
      font-size: 16px;
    }
  }
</style>