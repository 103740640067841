var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mb-nav-alert-container",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-nav-alert-row"},[_c('b-col',{staticClass:"mb-no-padding mb-nav-alert d-none d-sm-none d-md-none d-lg-block d-xl-block",attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.$t(
              'global.safeometer_securityWarning',
              {
                linkPrivacy: this.$t('safeometer_externalLInk_privacy'),
                linkHistory: this.$t('safeometer_externalLInk_deleteHistory'),
                linkClassPrivacy:'mb-link',
                linkClassHistory:'mb-link'
              }
          ))}}),_c('b-col',{staticClass:"mb-no-padding d-block d-sm-block d-md-block d-lg-none d-xl-none",attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-nav-alert mb-nav-alert-mobile"},[_c('div',{staticClass:"mb-nav-alert-mobile-content",domProps:{"innerHTML":_vm._s(_vm.$t(
              'global.safeometer_securityWarning',
              {
                linkPrivacy: this.$t('safeometer_externalLInk_privacy'),
                linkHistory: this.$t('safeometer_externalLInk_deleteHistory'),
                linkClassPrivacy:'mb-link',
                linkClassHistory:'mb-link'
              }
          ))}})]),_c('div',{staticClass:"mb-nav-alert-spotlight-text"},[_vm._v(" "+_vm._s(_vm.$t('safeometer_spotlight_text'))+" ")]),_c('b-img',{staticClass:"mb-nav-alert-biz",attrs:{"src":require('@/assets/images/biz_logo/' + _vm.$t('biz_logo')),"alt":"Illustration"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }